import React from "react"
import { graphql } from "gatsby"
import { get } from "../../util/functions"
import Button from "../../shared/Button/Button"
import styled from "styled-components"
import color from "../../style/color"
const BlogImageBanner = ({ node }) => {
  const {
    sectionBody,
    sectionHeading,
    cta,
    relationships,
    field_image_desktop,
  } = node
  const { alt } = field_image_desktop
  const image = get(
    ["field_image_desktop", "localFile", "publicURL"],
    relationships,
    ""
  )
  const { ctaUrl, ctaText } = cta || {}
  return (
    <Container>
      <LeftCol>
        <h3>{sectionHeading}</h3>
        <p>{sectionBody}</p>
        <BtnContainer>
          <Button text={ctaText} href={ctaUrl} size="small" />
        </BtnContainer>
      </LeftCol>
      <RightCol>
        <img src={image} alt={alt} />
      </RightCol>
    </Container>
  )
}

export default BlogImageBanner

export const fragment = graphql`
  fragment BlogImageBanner on paragraph__blog_image_banner {
    id
    sectionHeading: field_section_heading
    sectionBody: field_section_body
    cta: field_cta {
      ctaUrl: uri
      ctaText: title
    }
    field_image_desktop {
      alt
    }
    relationships {
      field_image_desktop {
        localFile {
          publicURL
        }
      }
    }
  }
`

const Container = styled.div`
  border-top: 1px solid ${color.keyline.grey};
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: center;
  border-radius: 4px;
  padding: 35px 0 35px;
  background: white;
  margin: 50px auto 0;

  @media (max-width: 1023px) {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 5rem 0 0;
  }

  @media (max-width: 767px) {
    padding: 5rem 0 0;
  }
`
const LeftCol = styled.div`
  margin-right: 4.23%;
  width: 57%;

  h3 {
    margin-bottom: 8px;
    font-family: Futura;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    color: #49ddc2;

    @media (max-width: 767px) {
      font-size: 18px;
      line-height: 28px;
      margin-bottom: 5px;
    }
  }

  p {
    font-size: 16px;
    line-height: 26px;
    color: #666666;
    margin-bottom: 17px;

    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 24px;
    }
  }
  @media (max-width: 1023px) {
    margin-bottom: 0;
    width: 100%;
    margin-right: 0;
    text-align: center;
  }
`
const RightCol = styled.div`
  width: 43%;
  display: flex;
  align-items: center;
  justify-content: center;

  & img {
    width: 100%;
    max-width: 400px;
  }
  @media (max-width: 1023px) {
    justify-content: center;
    margin-bottom: 10px;
    width: 100%;
  }
`

const BtnContainer = styled.div`
  width: 100%;
  max-width: 230px;

  @media (max-width: 1023px) {
    margin: 0 auto;
  }
`
