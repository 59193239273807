import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import Layout from "../helper/layout"
import { getParagraph } from "../util/blogParagraphHandler"
import Helmet from "react-helmet"
import styled from "styled-components"
import Footer from "../shared/Footer/FooterDrupal"
import moment from "moment"
import NavigationContainer from "../shared/Navigation/NavigationDrupal"
import LogoTop from "../shared/LogoTop/LogoTop"
import BlogSidebar from "../components/Blog/BlogSidebar"
import BlogHeader from "../components/Blog/BlogHeader"
import { get } from "../util/functions"
import color from "../style/color"

import linkedin from "../images/Blog/linkedin-circle.svg"
import linkedinHover from "../images/Blog/linkedin-light.svg"

import facebook from "../images/Blog/facebook-logo-circle.svg"
import facebookHover from "../images/Blog/facebook-logo-light.svg"

import twitter from "../images/Blog/twitter-logo-circle.svg"
import twitterHover from "../images/Blog/twitter-logo-light.svg"

import Img from "gatsby-image"

const BlogTemplate = ({ data }: any) => {
  const paragraphs = data.page.relationships.paragraphs.map(getParagraph)
  const [menuIsOpen, toggleMenu] = useState(false)
  const [pageUrl, setPageUrl] = useState("/")

  useEffect(() => {
    if (typeof window !== "undefined") {
      setPageUrl(window.location.href)
    }
  }, [])

  const renderBlogHeader = () => {
    const header = data.page.relationships.field_blog_header
    if (header) {
      const imageDesktop = get(
        ["relationships", "field_image_desktop", "localFile", "publicURL"],
        header,
        ""
      )
      const imageMobile = get(
        ["relationships", "field_image_mobile", "localFile", "publicURL"],
        header,
        ""
      )
      const breadcrumb = get(["field_breadcrumb"], header, [])

      return (
        <BlogHeader
          imageDesktop={imageDesktop}
          imageMobile={imageMobile}
          breadcrumb={breadcrumb}
        />
      )
    }
  }
  const profilePicture = get(
    ["field_profile_picture", "localFile", "childImageSharp", "fixed"],
    data.page.relationships,
    ""
  )

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{data.page.title}</title>
        <meta name="description" content={data.page.field_meta_description} />
        {process.env.GATSBY_AWS_BRANCH !== "master" && (
          <meta name="robots" content="noindex, nofollow" />
        )}
        <link
          rel="canonical"
          href={`https://www.whiteandcodental.co.uk` + data.page.path.alias}
        />
        <script type="application/ld+json">
          {`
          {
          "@context": "http://schema.org",
          "@type": "Organization",
          "name": "White & Co",
          "url": "https://www.whiteandcodental.co.uk/",
          "address": "19 Battersea Rise, London, SW11 1HG",
          "sameAs": [
            "https://www.facebook.com/whiteandcodental/",
            "https://www.instagram.com/whiteandcodental/"
            ]
          }
        `}
        </script>
      </Helmet>
      <LogoTop />
      <NavigationContainer
        slug={"/blog"}
        toggleMenu={toggleMenu}
        menuIsOpen={menuIsOpen}
      />
      {renderBlogHeader()}
      <PageContainer>
        <ContentContainer>
          <PostHeading>{data.page.field_blog_post_title}</PostHeading>

          <AuthorContainer>
            {/* {profilePicture && (
              <AuthorImage
                fixed={profilePicture}
                alt="Author profile picture"
                placeholderStyle={{ opacity: 0 }}
              />
            )} */}

            <RightCol>
              <Author>{data.page.relationships.uid.name}</Author>
              <RightColBottomRow>
                <Date>{moment(data.page.created).format("DD MMM YYYY")}</Date>
                {data.page.field_minutes_to_read && (
                  <>
                    <Dot />
                    <ReadTime>
                      {data.page.field_minutes_to_read} min read
                    </ReadTime>
                  </>
                )}
              </RightColBottomRow>
            </RightCol>
          </AuthorContainer>

          {paragraphs}
          <ShareContainer>
            <ShareHeading>SHARE THIS STORY</ShareHeading>

            <SocialMediaContainer>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href={`https://www.linkedin.com/shareArticle?mini=true&url=&title=&summary=&source=${pageUrl}`}
              >
                <SocialMediaIcon
                  icon={linkedin}
                  hoveredIcon={linkedinHover}
                  title="LinkedIn Logo"
                />
              </a>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href={`https://www.facebook.com/sharer/sharer.php?u=${pageUrl}`}
              >
                <SocialMediaIcon
                  icon={facebook}
                  hoveredIcon={facebookHover}
                  title="Facebook Logo"
                />
              </a>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href={`https://twitter.com/intent/tweet?text=${pageUrl}`}
              >
                <SocialMediaIcon
                  icon={twitter}
                  hoveredIcon={twitterHover}
                  title="Twitter Logo"
                />
              </a>
            </SocialMediaContainer>
          </ShareContainer>
        </ContentContainer>
        <SideBar id="">
          <BlogSidebar />
        </SideBar>
      </PageContainer>
      <Footer />
    </Layout>
  )
}

export default BlogTemplate

const AuthorContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 5px;
  @media (max-width: 1023px) {
    margin-top: 20px;
  }
`

const AuthorImage = styled(Img)`
  height: 50px !important;
  width: 50px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 16px;
`

const RightCol = styled.div`
  display: flex;
  flex-direction: column;
`

const RightColBottomRow = styled.div`
  display: flex;
  align-items: center;
`

const Author = styled.p`
  font-size: 14px;
  font-weight: bold;
  // color: #999999;
  margin-bottom: 3px;
`

const Date = styled.p`
  font-size: 14px;
  font-weight: 200;
  font-weight: bold;
`

const Dot = styled.div`
  height: 5px;
  width: 5px;
  border-radius: 50%;
  background-color: #061b30;
  margin: 0 5px;
`

const ReadTime = styled(Date)``

const ShareContainer = styled.div`
  margin-bottom: 111px;
  padding-top: 50px;
  @media (max-width: 1023px) {
    margin-bottom: 50px;
  }
`

const ShareHeading = styled.h3`
  font-family: "Open Sans";
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 2px;
  color: #000000;
  margin-bottom: 17px;
  @media (max-width: 1023px) {
    text-align: center;
  }
`

const SocialMediaContainer = styled.div`
  display: flex;
  & a {
    margin-right: 20px;
  }
  @media (max-width: 1023px) {
    justify-content: center;
  }
`
interface ISocialMediaIcon {
  icon?: string
  hoveredIcon?: string
}
const SocialMediaIcon = styled.div<ISocialMediaIcon>`
  height: 50px;
  width: 50px;
  background-image: url(${props => props.icon});

  &:hover,
  &:active {
    background-image: url(${props => props.hoveredIcon});
  }
`

const PostHeading = styled.h2`
  font-family: headingFontLight;
  font-size: 38px;
  line-height: 48px;
  font-weight: 300;
  color: ${color.brand.blue};
  width: 92%;
  max-width: 914px;

  @media (max-width: 1023px) {
    width: 100%;
    font-size: 24px;
    line-height: 34px;
  }
`

const ContentContainer = styled.div`
  width: 670px;
  @media (max-width: 1200px) {
    width: 60%;
  }
  @media (max-width: 1023px) {
    width: 100%;
    padding: 0 25px;
  }
`

const SideBar = styled.div`
  @media (min-width: 1024px) and (max-width: 1200px) {
    width: 35%;
  }
`

const PageContainer = styled.div`
  width: 92%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding-top: 36px;
  transform: translateZ(0px);
  @media (max-width: 1023px) {
    width: 100%;
    flex-direction: column;
  }
`

export const pageQuery = graphql`
  query($slug: String!) {
    page: nodeBlog(fields: { slug: { eq: $slug } }) {
      id
      path {
        alias
      }
      drupal_id
      created
      title
      field_meta_description
      field_blog_post_title
      field_minutes_to_read
      relationships {
        field_profile_picture {
          uri {
            url
          }
          localFile {
            childImageSharp {
              fixed(width: 50, quality: 50) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
        uid {
          relationships {
            file__file {
              uri {
                url
              }
            }
          }
        }
        field_blog_header {
          field_breadcrumb {
            uri
            title
          }
          relationships {
            field_image_desktop {
              localFile {
                publicURL
              }
            }
            field_image_mobile {
              localFile {
                publicURL
              }
            }
          }
        }
        paragraphs: field_blog_component {
          type: __typename
          ...BlogTextContent
          ...BlogImage
          ...BlogImageBanner
          ...BlogYoutube
          ...BlogVideo
          ...BlogQuote
        }
      }
    }
  }
`
